import type Alpine from "alpinejs"

export function initSidebarStore(alpine: typeof Alpine) {
  alpine.store("sidebar", {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    show: alpine.$persist(false),
    toggle() {
      this.show = !this.show
    },
    hide() {
      this.show = false
    },
    open() {
      this.show = true
    },
  })
}
