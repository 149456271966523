import type Alpine from "alpinejs"

// In development, you need to go to chrome://flags/#unsafely-treat-insecure-origin-as-secure
// enable the flag and add http://lvh.me:3000 to the list of exceptions.
// The clipboard does not work on non-https websites. As far as I can tell,
// There is no way to do this for Firefox at this time.
// https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard#browser_compatibility
// We may also need to deal with asking for permission in the future
// https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API

export default function (alpine: typeof Alpine) {
  alpine.magic("clipboard", () => {
    return (subject) => navigator.clipboard?.writeText(subject)
  })
}
