import Alpine from "alpinejs"

import { isntNil } from "~/src/lib/any"

type Preference = {
  name: string
  required: boolean
  included: boolean
  response?: string
  preferenceResponseId?: string
  parentProps?: ParentProperties
  responsesNotRequiringDelivery: { [key: string]: string }
}

type AddedPreference = { key: string } & Partial<Preference>

type PreferenceRecord = { [key: string]: Preference }

type ParentProperties = { id: string; matchingPreferenceResponseId: string; matchingResponse: string }

type DataObject = {
  optedOut: boolean
  invalidPreferences: Preference[]
  preferences: PreferenceRecord
  isDeliveryRequired?: boolean
  init: () => void
  addPreference: (fields: AddedPreference) => void
  clearResponses: () => void
  validate: (preference: Preference) => boolean
  isValidPreferences: () => boolean
  displayName: (preference: Preference) => string
  registerPreference: { ["x-init"]: () => void }
  doSelectedResponsesRequireDelivery: (selectedPrefs: Preference[]) => boolean
  getSelectedPreferences: (prefs: PreferenceRecord) => Preference[]
}

export function recipientFormData(alpine: typeof Alpine) {
  alpine.data("orderRecipient", function (optedOut: boolean): DataObject {
    return {
      optedOut: optedOut,
      invalidPreferences: [],
      preferences: {
        default_variant: {
          name: "Default",
          required: false,
          included: true,
          response: "ok",
          responsesNotRequiringDelivery: {},
        },
      },

      init() {
        this.$watch("preferences", (prefs: { [key: string]: Preference }) => {
          this.invalidPreferences = Object.values(prefs).filter((pref) => !this.validate(pref))
          const selectedPrefs = this.getSelectedPreferences(prefs)
          this.isDeliveryRequired = this.doSelectedResponsesRequireDelivery(selectedPrefs)

          // If the user has opted out then selects a response, opt them back in
          if (this.optedOut === true) {
            const hasResponded = Object.values(prefs).some((pref) => {
              if (pref.included) return false
              return isntNil(pref.response || pref.preferenceResponseId)
            })

            if (hasResponded) this.optedOut = false
          }
        })

        this.$watch("optedOut", (optedOut: boolean) => {
          if (optedOut === true) this.clearResponses()
        })
      },

      getSelectedPreferences(prefs: { [key: string]: Preference }) {
        return Object.values(prefs).filter(
          (pref) => pref.preferenceResponseId != null && pref.preferenceResponseId?.trim() != ""
        )
      },

      doSelectedResponsesRequireDelivery(selectedPrefs: Preference[]): boolean {
        // If _any_ selected pref response requires delivery (not an eGift for example) then delivery is required
        return selectedPrefs.some(
          ({ preferenceResponseId, responsesNotRequiringDelivery }) =>
            responsesNotRequiringDelivery == null ||
            !responsesNotRequiringDelivery.hasOwnProperty(String(preferenceResponseId))
        )
      },

      registerPreference: {
        ["x-init"]() {
          this.addPreference(JSON.parse(this.$el.dataset.props))
        },
      },

      addPreference(fields) {
        const { key, response, preferenceResponseId, ...restPreference } = fields

        this.preferences[key] = {
          ...restPreference,
          response: response?.toString(),
          preferenceResponseId: preferenceResponseId?.toString(),
        }
      },

      clearResponses() {
        const newPreferences: PreferenceRecord = {}

        Object.entries<Preference>(this.preferences).forEach(([key, preference]) => {
          if (preference.included) {
            newPreferences[key] = { ...preference }
          } else {
            newPreferences[key] = { ...preference, response: undefined, preferenceResponseId: undefined }
          }
        })

        this.preferences = newPreferences
      },

      validate(preference) {
        const { required, response, preferenceResponseId, included, parentProps } = preference

        // Included preferences are always valid
        if (included) return true

        if (parentProps != null) {
          const parentPreference = this.preferences[parentProps.id]

          // Abort if child but parent can't be found
          if (parentPreference == null) return true

          // Abort if parent preference response for this child isn't selected
          if (parentPreference.preferenceResponseId != parentProps.matchingPreferenceResponseId) {
            return true
          }
        }

        // Always valid if not required
        if (required == false) return true

        // Valid if a response or a preferenceResponseId is set
        return (
          (preferenceResponseId != null && preferenceResponseId.trim() != "") ||
          (response != null && response.trim() != "")
        )
      },

      isValidPreferences(): boolean {
        return this.invalidPreferences.length == 0
      },

      displayName(preference: Preference): string {
        const { parentProps } = preference

        if (isntNil(parentProps)) {
          const parentPreference = this.preferences[parentProps.id]

          if (isntNil(parentPreference)) {
            return parentPreference.name + " - " + parentProps.matchingResponse + ": " + preference.name
          }
        }

        return preference.name
      },
    }
  })
}
